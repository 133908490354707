import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import SiteMeta from "../components/SiteMeta"
import Page from "../components/Page/Page"
import BackgroundImage from "../components/BackgroundImage"
import commonStyles from "./pages.module.scss"
import styles from "./index.module.scss"
import classNames from "classnames"
import Image from "../components/Image"

const IndexPage: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.IndexQuery>(graphql`
    query Index {
      self: file(relativePath: { eq: "lviv-edit.jpg" }) {
        childImageSharp {
          fixed(width: 240, height: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image: file(
        relativePath: { eq: "bernard-hermant-WzJmMBh5O9Y-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SiteMeta />
      <Page className={commonStyles.center}>
        <BackgroundImage
          displayId="index-header"
          fluid={data.image?.childImageSharp?.fluid}
          gradientBottom
          className={styles.bgImage}
        />
        <div className={classNames(commonStyles.container, styles.content)}>
          <div className={styles.imageTitle}>
            <Image
              displayId="index-header"
              visible
              fixed={data.self?.childImageSharp?.fixed}
              className={styles.image}
            />

            <div>
              <h3 className={styles.subtitle}>Hi, I’m</h3>
              <h1 className={styles.title}>Aleksi Munter</h1>
            </div>
          </div>
          <div className={styles.textContent}>
            <p className={commonStyles.lead}>
              I’m a <strong>musician</strong>, worked on 25+ internationally
              released albums as a performer, arranger, producer, graphic
              designer and composer, with extensive experience in music and
              touring business.
              <br />
              <Link to={"/music/"} className={styles.link}>
                See my works.
              </Link>
            </p>
            <p className={commonStyles.lead}>
              I’m also a <strong>software developer</strong> with{" "}
              {new Date().getFullYear() - 2003} years of hands-on experience on
              every aspect of software development from architecture design and
              implementation to project management.
              <br />
              <Link to={"/software/"} className={styles.link}>
                Check out my résumé.
              </Link>
            </p>
          </div>
        </div>
      </Page>
    </Layout>
  )
}

export default IndexPage
